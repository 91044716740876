import styles from './grid.module.css';
import Level from '../Level';
import { DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import apiClient from '../../lib/apiClient';
import { useState } from 'react';

const Grid = ({data}) => {

  const removeTrader = async() => {
    try {
      const response = await apiClient.delete(
        `/${data.id}`,
      );
      console.log(response);
    }
    catch(error) {
      console.log(error);
      window.location.reload();
    }
  }

  const options = [
    {
      key: '1',
      danger: true,
      label: (
        <span onClick={removeTrader}>
          <DeleteOutlined style={{color: "red", marginRight: "5px"}} />
          Remove
        </span>
      ),
    },
  ];

  return (
    <div className={styles.gridContainer}>
      <div className={styles.grid} style={{border: `3px solid ${Number(data.profit) > 0 && data.status === "STOPPED" ? "#FFD700" : Number(data.profit) > 0 ? "green" : "red"}`}}>
        <div className={styles.header}>
          <div className='column'>
            <span className={styles.headerText}>{data.symbol}</span>
            <span className={styles.smallText}>ID: {data.id}</span>
            <span className={styles.smallText}>STATUS: {data.status}</span>
            <span className={styles.smallText}>MONEY IN: {Number(data.moneyIn).toFixed(2)}$</span>
            <span className={styles.smallText}>PROFIT TAKEN: {Number(data.profitTaken).toFixed(2)}$</span>
            <span className={styles.smallText}>SPEED: {Number(data.speed).toFixed(2)}</span>
            <span className={styles.smallText}>LEVERAGE: {Number(data.leverage).toFixed(2)}</span>
            <span className={styles.smallText}>AIM: {Number(data.aim).toFixed(2)}</span>
            <span className={styles.smallText}>LIVES: {Number(data.lives).toFixed(2)}</span>
            <span className={styles.smallText}>TP: {Number(data.takeProfit).toFixed(2)}</span>
            <span className={styles.smallText}>SL: {Number(data.stopLoss).toFixed(2)}</span>
            <span className={styles.smallText}>PEAK: {Number(data.peak).toFixed(2)}</span>
          </div>

          <div className={styles.leftColumn}>
              <span className={styles.headerText} style={{marginRight: "10px"}}>{Number(data.profit).toFixed(2) || 0}$</span>
              <div className={styles.flex}>
                <div className={styles.tag}>
                  {data?.type}
                </div>
                <div className={styles.tag}>
                  {data?.mode}
                </div>
              </div>
              <Button onClick={removeTrader} style={{width: "100%"}}>
                <DeleteOutlined style={{color: "red", marginRight: "5px"}} />
              </Button>
          </div>
        </div>
        <div className={styles.levels}>
          {data.levels?.map(obj => <Level key={Math.random() * 100} data={obj} children={data.transactions?.filter(tx => tx.price === obj.price)} />)}
        </div>
      </div>
    </div>
  );
}

export default Grid;
