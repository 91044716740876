import { ConfigProvider } from 'antd';
import './App.css';
import Home from './components/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './components/Login';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#FFD700",
              colorPrimaryBorder: "#FFD700",
              colorText: "#FFD700",
            },
          }}
        >
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </ConfigProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;
