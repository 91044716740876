import { useEffect, useState } from 'react';
import AddTrader from '../AddTrader';
import Grid from '../Grid';
import apiClient from '../../lib/apiClient';

const Home = () => {
  const [traders, setTraders] = useState([]);
  const [data, setData] = useState([]);
  const [profit, setProfit] = useState(0);
  const [loop, setLoop] = useState();

  useEffect(() => {
    setLoop(setInterval(getTraders, 1000));
    return () => {
      clearInterval(loop);
    };
  }, [data]);

  const getTraders = async() => {
    try {
      const response = await apiClient.get("/");
      if(response?.data?.profit) setProfit(Number(response?.data?.profit).toFixed(2));
      if(response?.data?.traders && response?.data?.traders.length > 0) setTraders(response?.data?.traders);
      else setData([]);
    }
    catch(error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if(traders && traders.length > 0) {
      const data = [];
      for(let trader of traders) {  
        const currentData = {
          id: trader._id,
          symbol: trader.symbol,
          profit: trader.profit,
          profitTaken: trader.profitTaken,
          totalProfit: trader.totalProfit,
          status: trader.status,
          takeProfit: trader.takeProfit,
          speed: trader.speed,
          aim: trader.aim,
          stopLoss: trader.stopLoss,
          moneyIn: trader.moneyIn,
          levels: trader.levels,
          leverage: trader.leverage,
          type: trader.type,
          lives: trader.lives,
          mode: trader.mode,
          peak: trader.peak,
          transactions: trader.transactions
        }
        data.push(currentData);
      }
      setData(data);
    }
    else {
      setData([]);
    }
  }, [traders]);

  return (
    <>
      <div className='header'>
        <div className='profit' style={{color: profit > 0 ? "green" : "red", border: `2px dashed ${profit > 0 ? "green" : "red"}`}}>{profit}$</div>
        <AddTrader />
      </div>
      <div className='row wrap justifyStart alignStart'>
        {data && data.length > 0 && data?.map(obj => <Grid key={Math.random() * 100} data={obj} />)}
      </div>
    </>
  );
}

export default Home;
